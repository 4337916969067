import { Component, Input, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material';
import { Router } from '@angular/router';
import { TimerService } from '@core/services/timer/timer.service';
import { ModalAuctionLeaveComponent } from 'src/app/features/auction/modal-auction-leave/modal-auction-leave.component';
import { ModalRegisterLeaveComponent } from 'src/app/features/auction/modal-register-leave/modal-register-leave.component';

@Component({
  selector: 'app-firbid-header',
  templateUrl: './firbid-header.component.html',
  styleUrls: ['./firbid-header.component.scss']
})
export class FirbidHeaderComponent implements OnInit {

  @Input('navigateToOnClose') navigateToOnClose: string;

  constructor(
    public _timerService: TimerService,
    private dialog: MatDialog,
    private router: Router
  ) { }

  ngOnInit() {

  }
  redirect() {
    const currentUrl = this.router.url;

    if (currentUrl === '/registro/persona' || currentUrl === '/registro/empresa') {
      window.location.href = 'https://firbid.com';
    }
  }

  close() {
    if(this.navigateToOnClose) return this.router.navigate([this.navigateToOnClose]);
    console.log(this.navigateToOnClose)
    const status = this._timerService.getCloseStatus();
    switch (status) {
      case 1:
        this.leaveDialog();
        break;
      case 2:
        this.leaveRegisterDialog();
        break;
      case 3:
        window.location.href = 'https://www.firbid.com/';
        break;
      case 4:
        this.router.navigate(['main/subasta']);
        break;
      default:
        this.router.navigate(['main/subasta']);
        break;
    }
  }

  leaveDialog() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    const dialogRef = this.dialog.open(ModalAuctionLeaveComponent, dialogConfig);
  }

  leaveRegisterDialog() {
    const dialogConfig = new MatDialogConfig();
    // dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    const dialogRef = this.dialog.open(ModalRegisterLeaveComponent, dialogConfig);
  }

}
